import React, { useState } from "react";
import "./blog.css";
import { BlogCdk } from "./blog/cdk";
import { BlogCompliance } from "./blog/compliance";
import { BlogAmplify } from "./blog/amplify";
import { BlogMicroservices } from "./blog/microservice";

export const Blog = () => {

  const [index, setIndex] = useState(0);
  const blogTitles = [
    "IT Compliance",
    // "IT Risk Management",
    // "Managing Engeering Teams",
    "Cloud infrastructure as code",
    // "CI/CD",
    "Micro frontends",
    "Micro services"
  ];

  return (
    <section>
      <div id="blog" className="text-center">
        <div className="container">
          <div className="">
            <h2>Blog directory</h2>
          </div>
          <div>
            <ul className="blog-nav">
              {blogTitles.map((title, i) => (
                <li key={i}>
                  <a className={index === i ? "active" : ""} onClick={() => setIndex(i)}>
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {index === 0 ? <BlogCompliance/> : null}
      {index === 1 ? <BlogCdk/> : null}
      {index === 2 ? <BlogAmplify/> : null}
      {index === 3 ? <BlogMicroservices/> : null}
    </section>
  )
};