import React from "react";
import "./styles.css";
import { Image } from "../image";

export const BlogCdk = () => {
  return (
    <div id="blog-container">
      <h1>Cloud infrastructure as code with CDK</h1>
      <img src="img/blog/cdk-pipeline.jpg"/>
      <div>
        <p>This post is relavant if your organisation would like to automate the deployment and maintainance of cloud
          infrastructure.</p>
        <h3>What is CDK</h3>
        <p><strong>AWS CDK (Cloud Development Kit) </strong>allows engineers to define cloud infrastructure using familiar
          programming languages like TypeScript, JavaScript, Python, Java, C#, and Go.</p>
        <p>Let's talk about an example.</p>
        <p><strong>Example.</strong> You need to create a MySQL database on AWS Cloud.</p>
        <p><strong>Quick solution</strong>: You can create it manually on the UI.</p>
        <ol>
          <li>
            <p>1. Login to your AWS account at <a href="https://aws.amazon.com/" target="_blank">https://aws.amazon.com/</a>.
            </p>
          </li>
          <li>
            <p>2. Go to RDS</p>
          </li>
          <li>
            <p>3. Create a database with your desired configuration</p>
          </li>
        </ol>
        <figure>
          <img src="img/blog/rds1.png"/>
          <br/>
          <br/>
          <img src="img/blog/rds2.png"/>
          <figcaption></figcaption>
        </figure>
        {/* <figure>
          <figcaption></figcaption>
        </figure> */}
        <p>It is easy to create an instance on the UI. However, think of these scenarios:</p>
        <ol>
          <li>
            <p>1. You need to deploy a RDS instance with the exact same configuration into Dev, Staging, and Prod accounts.</p>
          </li>
          <li>
            <p>2. You need to restore a database in a disaster event with minimum RTO and RPO.</p>
          </li>
          <li>
            <p>3. You want to review the changes being applied into your RDS instance before it is applied.</p>
          </li>
          <li>
            <p>4. Your organisation wants to enforce a standard on all of the RDS instances.</p>
          </li>
          <li>
            <p>5. Your organisation wants to automate the backup of every RDS instance.</p>
          </li>
        </ol>
        <p>Ideally, an organisation wouldn't take the risk and overload of achieving the above with manual effort.</p>
        <p><strong>Better solution: </strong>You can achieve all of the above requirements and more with infrastructure as
          code, specially with CDK. Please see the below dummy CDK code for an example.</p>
        <figure>
          <img src="img/blog/rds-cdk.png"/>
          <figcaption>Example CDK code to create a RDS instance</figcaption>
        </figure>
        <h3>Why CDK</h3>
        <p><strong>Code:</strong></p>
        <p>More or less, it is code written in the most familiar programming languages. We will create classes, interfaces,
          unit tests, integration tests just as you do already on your other applications.</p>
        <p><strong>Support:</strong></p>
        <p>CDK is the supported inftrastructure development tool when you need support from AWS. They don't give support on
          other inftrastructure tools, such as, terraform.</p>
        <p><strong>CI/CD:</strong></p>
        <p>You can define pipeline which deploys your cloud infrastructure as code.</p>
        <p>A same CDK code can be applied to multiple accounts (environments as well).</p>
        <p><strong>Code review:</strong></p>
        <p>Developers raise a merge request for their changes on the cloud infrastructure and a peer can review the changes
          before it is rolled out.</p>
        <p><strong>Compliance:</strong></p>
        <p>When the organisation enforces infrastructure maintainance only as code, the compliance standards can be
          implemented on re-used code blocks (also known as modules or library). This will save time for engineers while
          making sure the cloud infrastructures of the organisation is compliant.</p>
        <p><strong>Security:</strong></p>
        <ol>
          <li>
            <p>Automated security scanner can be placed in the CI/CD pipelines. This prevents from unwanted outcome on the
              production environment.</p>
          </li>
          <li>
            <p>Even before raising a merge request, developers can validate their changes with local security validation.
            </p>
          </li>
        </ol>
        <p><strong>Knowledge sharing:</strong></p>
        <p>Knowledge from experienced engineers will be stored as infrastructure code. This will help less experienced
          engineers to learn and get experience. </p>
        <h3>Deployment</h3>
        <p>For an organisation, coming up with a scalable, compliant deployment tool is crucial. There are multiple tools
          known for their reliability. However when you have CDK infrastructure, choosing the infrastructure service with
          right capability is important too. AWS provides CloudFormation service for this purpose. My next post will cover
          this area.</p>
        <h3>Alternatives to CDK</h3>
        <p>Terraform is valid consideration in terms of inftrastructure development. I will write about terraform in my next
          post.</p>
        <h3>For more</h3>
        <p><a href="https://docs.aws.amazon.com/cdk/v2/guide/home.html"
          target="_blank">https://docs.aws.amazon.com/cdk/v2/guide/home.html</a></p>
      </div>
    </div>
  )
};