import React from "react";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import { Features } from "./features";
import { Header } from "./header";

export const Home = (props) => {
  return (
    <React.Fragment>
      <Header data={props.data.Header} />
      <Features data={props.data.Features} />
      <Services data={props.data.Services} />
      {/* <Gallery data={props.data.Gallery} /> */}
      <Testimonials data={props.data.Testimonials} />
      <Team data={props.data.Team} />
      <Contact data={{contact: props.data.Contact, contactMongolia: props.data.ContactMongolia}} />
    </React.Fragment>
  )
};