import React from "react";
import "./styles.css";

export const BlogAmplify = () => {
  return (
    <div id="blog-container">
      <h1>What is Micro Frontends</h1>
      <div>
        <p>With Micro Frontends, you can build smaller, easy-to-maintain front-end applications as many as you want. The challenge is that you have to integrate these applications as one master application. You only need a small team of 3-5 people who will design, develop, test, and release one front-end app. They can maintain more than one applications as well.</p>
        <h3>Why you need micro front-end:</h3>
        <p>1. Quick to test</p>
        <p>2. Easy to maintain</p>
        <p>3. Easy to run experiments (A/B testing)</p>
        <p>4. Integrated to the master application via API.</p>
        <p>5. Flexible to renovate</p>
        <p>Now we know why the latest trend is micro frontend application in the front-end application development industry.
          Let's look at the maintainance of a single front-end application.</p>
        <h2>AWS Amplify</h2>
        <p>You can deploy a React application with AWS Amplify quickly. Literally we are talking about
          20 minutes of time, considering that you already have a code repository with a functioning React web app.</p>
        <h3>Why Amplify:</h3>
        <ol>
          <li>
            <p>Helps to create your deployment pipeline with simplicity</p>
          </li>
          <li>
            <p>Integrated with Amazon Route53 for managing your domains. You can even buy a new domain and have it
              functional in 5 minutes.</p>
          </li>
          <li>
            <p>Hosts your web app in CDN with Amazon CloudFront</p>
          </li>
          <li>
            <p>Web authentication is available out-of-the-box</p>
          </li>
          <li>
            <p>Supports server side rendering by utilising Lambda on Edge</p>
          </li>
          <li>
            <p>Supports different environments for your web app. Imagine you can deploy your feature branch on a subdomain.
              For example: <a href="http://staging.your-domain.com" target="_blank">staging.your-domain.com</a></p>
          </li>
        </ol>
        <h3>Steps to follow:</h3>
        <p>1. Login to your AWS account</p>
        <p>2. Navigate to AWS Amplify</p>
        <p>3. Connect to your code repository</p>
        <p>4. Make sure you have a Dockerfile. If you don’t, no need to worry because it is fairly simple to create it. See
          the <a href="https://docs.google.com/document/d/1HYKU_OIWhq7jMw9Zr1u1aRZ2A6A3Su1bKVuNGyB99hs/edit?usp=sharing"
            target="_blank">link</a></p>
        <p>5. Deploy the web app</p>
        <p>6. Visit your web application on your web browser</p>
        <p>7. You can buy a new domain with AWS Route53 (around $14 per year) and host your web app on your own domain.
          Otherwise, Amplify will make it available on a &lt;random&gt;.<a href="http://cloudfront.net"
            target="_blank">cloudfront.net</a></p>
        <figure>
          <img src="img/blog/amplify1.png"/>
          <figcaption>Step 3. Connect to your code repository</figcaption>
        </figure>
        <figure>
          <img src="img/blog/amplify2.png"/>
          <figcaption>Step 4. Build settings</figcaption>
        </figure>
        <figure>
          <img src="img/blog/amplify3.png"/>
          <figcaption>Step 5. Deploy</figcaption>
        </figure>
        <p>Here is how your Cloud infrastructure would looks like in your AWS account. But you don't need to maintain these because Amplify will manage them for you. You can keep concentrating on designing, developing, and improving your application.</p>
        <img src="img/blog/amplify-design.png"/>
        <p></p>
        <p></p>
      </div>
    </div>
  )
};