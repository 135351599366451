import React from "react";
import "./styles.css";

export const BlogCompliance = () => {
  return (
    <div id="blog-container">
      <h1>IT Compliance</h1>
      <div>
        <p><strong>WHAT is IT compliance?</strong></p>
        <p>IT compliance establishes clear guidelines for deploying and managing digital technologies in businesses and
          government agencies. It establishes which standards an organisation must satisfy in order to meet the criteria for
          IT security, data protection, data availability, and data integrity.</p>
        <p><strong>WHY is it important?</strong></p>
        <p>In order for your business to operate legally as a registered public company, you have to follow specific
          industry standards, laws, rules, and ethical conduct requirements that apply to your business.</p>
        <p>Without being compliant, you could face security breaches, loss of productivity, and reputational damage.
          Non-compliance might also lead to financial penalties, loss of clientele, disruptions in operations, and even
          regional lockouts. It's crucial for businesses to follow the rules to avoid these problems.</p>
        <p><strong>WHEN does it apply to your business?</strong></p>
        <p>Compliance is mandatory for a registrered public company. Even when your company is not a registrered public
          company, there could be consequences for being non-compliant against the law of the countries where your company
          operates in.</p>
        <p><strong>What is the NEXT?</strong></p>
        <p>We are happy to offer you professional consulting in IT compliance. With the experience of working with
          multi-national corporations, our consulting team will help you create a shortcut to the final outcome.</p>
        <p>For more on The New York Stock Exchange Compliance, please visit <a href="https://www.nyse.com/regulation"
          target="_blank">https://www.nyse.com/regulation</a></p>
        <p></p>
      </div>
    </div>
  )
};